import React from 'react';

import Icon from './Icon';

export default function NewsletterIcon({
  className = '',
  text = 'Newsletter',
}: { className?: string; text?: string }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 8C4.89543 8 4 8.89543 4 10V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V10C20 8.89543 19.1046 8 18 8H6ZM7.57343 10.6808L12 13.7793L16.4265 10.6808C16.879 10.364 17.5025 10.4741 17.8192 10.9265C18.1359 11.379 18.0259 12.0025 17.5734 12.3192L12.5734 15.8192C12.2291 16.0603 11.7708 16.0603 11.4265 15.8192L6.4265 12.3192C5.97405 12.0025 5.86402 11.379 6.18073 10.9265C6.49745 10.4741 7.12098 10.364 7.57343 10.6808Z"
          fill="#C1C7CE"
        />
        <path d="M6 7C6 5.89543 6.89543 5 8 5H16C17.1046 5 18 5.89543 18 7H6Z" fill="#959FAA" />
        <path d="M9 4C9 2.89543 9.89543 2 11 2H13C14.1046 2 15 2.89543 15 4H9Z" fill="#6E7883" />
      </svg>
    </Icon>
  );
}
